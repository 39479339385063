import React, { useState } from 'react'
import './Todo.css'
import TodoFrom from './TodoFrom'
import TodoList from './TodoList'


const Todoo = () => {

  const [input, setInput] = useState("");
  const [todos, setTodos] = useState([]);
  const [editId, setEditId] = useState(0);


  const handleChange = (e) => {
    e.preventDefault();
    if (editId) {
      const editTodo = todos.find((item) => item.id === editId)
      const updateTodo = todos.map((t) => 
        t.id === editTodo.id ? (t = { id: t.id, input }) : ({ id: t.id, input: t.input })
      )
      setTodos(updateTodo)
      setInput("")
      setEditId(0)
      return;
    }


    if (input !== "") {
      setTodos([{ id: `${input}-${Date.now()}`, input }, ...todos])
    } else {
      alert("Invalid Item")
    }
    setInput('');
  }

  const handleDelete = (id) => {
    const DelItem = todos.filter((item) => item.id !== id)
    setTodos([...DelItem])
  }

  const handleEdit = (id) => {
    const UpdateItem = todos.find((item) => item.id === id)
    setInput(UpdateItem.input)
    setEditId(id)
  }

  const RemoveAll = ()=>{
    setTodos([]);
  }

  return (
    <>
      <div className="todo">
        <h1>Todo</h1>
        <TodoFrom input={input} setInput={setInput} handleChange={handleChange} editId={editId} />
        <TodoList input={input} todos={todos} handleDelete={handleDelete} handleEdit={handleEdit} />

        <button className='btn' onClick={RemoveAll}>Remove All Data</button>
      </div>
    </>
  )
}

export default Todoo