import React from 'react'
import { UilFilePlusAlt } from '@iconscout/react-unicons'
import { UilEdit } from '@iconscout/react-unicons'




const TodoFrom = (props) => {
  return (
    <>
    <form className='todoForm' onSubmit={props.handleChange}>
        <input type="text" placeholder='Enter Items'  value={props.input} 
        onChange={(e)=>props.setInput(e.target.value)} />
        <button type='submit'>{props.editId?(<UilEdit />):(<UilFilePlusAlt size={30}/>)}</button>
    </form>
    </>
  )
}

export default TodoFrom