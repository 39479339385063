import React from 'react'
import { UilEdit } from '@iconscout/react-unicons'
import { UilTrashAlt } from '@iconscout/react-unicons'

const TodoList = (props) => {
    return (
        <>
            <ul>
                {
                    props.todos.map((item) => {

                        return (

                            <div className='menu' key={item.id}>
                                <li className='left'>{item.input}</li>
                                <span className='right'>
                                    <span onClick={() => props.handleEdit(item.id)}>
                                        <UilEdit /></span>
                                    <span onClick={() => props.handleDelete(item.id)}>
                                        <UilTrashAlt /></span>
                                </span>
                            </div>
                        )
                    })
                }

            </ul>
        </>
    )
}

export default TodoList